body{
    background-image: url("img.jpg");
    background-size: cover;
    background-position: center;
    background-position: top;
    background-repeat: no-repeat;
};

.contact-font{
   font-size: 30px !important;
}

